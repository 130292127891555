import { ClientRouter, replace } from "@with-cardinal/client-router";
import { TextInput } from "@with-cardinal/nest-fx";
import { BetterForm, RouterLink } from "@with-cardinal/router-fx";
import { HamburgerIcon } from "./components/hamburger-icon.js";
import "./plausible.js";
import { fonts } from "@with-cardinal/fonts";
import { SignupForm } from "./components/signup-form.js";
import "./style/index.css.js";
import { Fx } from "@with-cardinal/fx";

fonts("Biotif", "Inter");

ClientRouter.onChange = replace;
ClientRouter.start();

Fx.register("router-link", RouterLink);
Fx.register("text-input", TextInput, {
	filters: {
		otp: (value) => value.toUpperCase().trim(),
	},
});
Fx.register("better-form", BetterForm);
Fx.register("signup-form", SignupForm);
Fx.register("hamburger-icon", HamburgerIcon);
