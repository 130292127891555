export class Fx extends HTMLElement {
	static registeredComponents = new Map();
	events = [];

	static register(name, componentClass, opts) {
		Fx.registeredComponents.set(componentClass, opts);
		customElements.define(name, componentClass);
	}

	// adds an event listener. These will be removed when the element is removed
	// from the document
	listen(elem, type, fn) {
		this.events.push([elem, type, fn]);
		elem.addEventListener(type, fn);
	}

	// called when an element is attached to a document
	attach() {}

	// called when an element is detached from a document
	detach() {}

	connectedCallback() {
		if (this.isConnected) {
			this.attach(Fx.registeredComponents.get(this.constructor));
		}
	}

	adoptedCallback() {
		if (this.isConnected) {
			this.attach(Fx.registeredComponents.get(this.constructor));
		}
	}

	disconnectedCallback() {
		this.detach();

		for (const event of this.events) {
			event[0].removeEventListener(event[1], event[2]);
		}
	}
}
