import { ClientRouter } from "@with-cardinal/client-router";
import { Fx } from "@with-cardinal/fx";

export class RouterLink extends Fx {
	link;

	attach() {
		this.link = this.querySelector("a");
		this.listen(this.link, "click", (evt) => this.onClick(evt));
	}

	onClick(evt) {
		if (evt.button !== 0 || evt.metaKey || evt.ctrlKey || evt.shiftKey) {
			return;
		}

		evt.preventDefault();
		ClientRouter.push(this.link.href);
	}
}
